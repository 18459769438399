import React, { useEffect, useState } from "react";
import { Link, useLocation } from "react-router-dom";

const Site_bar = () => {
  const [activeLink, setActiveLink] = useState("");
  const location = useLocation();

  useEffect(() => {
    const path = location.pathname.split("/").pop();
    setActiveLink(path);
  }, [location]);

  const isActive = (path) => {
    return activeLink === path ? "active-link" : "";
  };

  return (
    <div>
      <div className="col-lg-2">
        <div className="dashboard_pages_list">
          <Link
            to="/dashboard"
            className={`dashboard_title ${isActive("dashboard")}`}
          >
            <img
              src={require("../assets/icons/dashboard_icon.webp")}
              alt="Dashboard"
            />
            <h3>Dashboard</h3>
          </Link>

          <Link
            to="/security"
            className={`dashboard_title ${isActive("security")}`}
          >
            <img
              src={require("../assets/icons/security.webp")}
              alt="Security"
            />
            <h3>Security</h3>
          </Link>

          <Link to="/kyc" className={`dashboard_title ${isActive("kyc")}`}>
            <img
              src={require("../assets/icons/Identification.webp")}
              alt="Identification"
            />
            <h3>Identification</h3>
          </Link>

          {/* <Link
            to="/Checkout"
            className={`dashboard_title ${isActive("Checkout")}`}
          >
            <img
              src={require("../assets/icons/deposit_icon.webp")}
              alt="Withdrawal"
            />
            <h3>Fiat Deposit</h3>
          </Link> */}
          <Link
            to="/withdraw"
            className={`dashboard_title ${isActive("withdraw")}`}
          >
            <img
              src={require("../assets/icons/withdraw_icon.webp")}
              alt="Withdrawal"
            />
            <h3>Withdrawal</h3>
          </Link>

          <Link
            to="/deposit"
            className={`dashboard_title ${isActive("deposit")}`}
          >
            <img
              src={require("../assets/icons/deposit_icon.png")}
              alt="Deposit"
            />
            <h3>Deposit</h3>
          </Link>
        

        

          <Link
            to="/rewards"
            className={`dashboard_title ${isActive("rewards")}`}
          >
            <img
              src={require("../assets/icons/my_reward_icon.webp")}
              alt="My Rewards"
            />
            <h3>My Rewards</h3>
          </Link>

          <Link
            to="/loginHistory"
            className={`dashboard_title ${isActive("deposithistory")}`}
          >
            <img
              src={require("../assets/icons/history_icon.webp")}
              alt="History"
            />
            <h3>History</h3>
          </Link>

          <Link
            to="/support"
            className={`dashboard_title ${isActive("support")}`}
          >
            <img src={require("../assets/icons/support.webp")} alt="support" />
            <h3>Support</h3>
          </Link>

          {/* <Link to="/settings" className={`dashboard_title ${isActive('settings')}`}>
            <img src={require("../assets/Settings_icon.png")} alt="Settings" />
            <h3>Settings</h3>
          </Link> */}
        </div>
      </div>
    </div>
  );
};

export default Site_bar;
