import React, { useEffect } from "react";
import Header from "./Header";
import { stakeOpt } from "../utils/mockData3";
import Pagination from "@mui/material/Pagination";
import Stack from "@mui/material/Stack";
import { getMethod, postMethod } from "../core/service/common.api";
import apiService from "../core/service/detail";
import { Link, useNavigate } from "react-router-dom";
import useState from "react-usestateref";
import { toast } from "react-toastify";
import { Bars } from "react-loader-spinner";
import moment from "moment";
import { env } from "../core/service/envconfig";



const Staking = () => {

  const navigate = useNavigate();

  const [refferHistoty, setRefferHistoty] = useState("");


  const [Rewardpage, setRewardpage, Rewardpageref] = useState("");
  const [airStatus, setAirStatus, airStatusref] = useState(0);


  const [siteLoader, setSiteLoader] = useState(false);
  const [profileData, setprofileData] = useState("");
  const [buttonLoader, setbuttonLoader] = useState(false);

  useEffect(() => {
    getProfile();
    getKycStatus();
    // getAirdropInfo();
  }, []);

  const title = "Welcome to Fidex";
  const getProfile = async () => {
    try {
      var data = {
        apiUrl: apiService.getrewardsinfo,
      };
      setSiteLoader(true);
      var resp = await getMethod(data);
      setSiteLoader(false);
      if (resp.status) {
        setRewardpage(resp.data);
        console.log(Rewardpageref.current, "resp");
      } else {
        setRewardpage("");
      }
    } catch (error) { }
  };

  // const getAirdropInfo = async () => {
  //   try {
  //     var data = {
  //       apiUrl: apiService.getAirdropInfo,
  //     };
  //     setSiteLoader(true);
  //     var resp = await getMethod(data);
  //     setSiteLoader(false);
  //     if (resp.status) {
  //       setAirStatus(resp.data);
  //       console.log(airStatusref.current, "resp---air");
  //     } else {
  //       setRewardpage("");
  //     }
  //   } catch (error) { }
  // }


  const claimKYC = async () => {
    try {
      var data = {
        apiUrl: apiService.getKYCreward,
      };
      setSiteLoader(true);
      var resp = await getMethod(data);
      setSiteLoader(false);
      if (resp.status) {
       showsuccessToast(resp.message);
        getProfile();
      } else {
        showerrorToast(resp.message);
      }
    } catch (error) { }
  };

  const claimDEPOSIT = async () => {
    try {
      var data = {
        apiUrl: apiService.getDepositreward,
      };
      setSiteLoader(true);
      var resp = await getMethod(data);
      setSiteLoader(false);
      if (resp.status) {
       showsuccessToast(resp.message);
        getProfile();
      } else {
        showerrorToast(resp.message);
      }
    } catch (error) { }
  };

  const claimTRADE = async () => {
    try {
      var data = {
        apiUrl: apiService.getTradereward,
      };
      setSiteLoader(true);
      var resp = await getMethod(data);
      setSiteLoader(false);
      if (resp.status) {
       showsuccessToast(resp.message);
        getProfile();

      } else {
        showerrorToast(resp.message);
      }
    } catch (error) { }
  };

  const getKycStatus = async () => {
    try {
      var data = {
        apiUrl: apiService.getKYC,
      };
      setSiteLoader(true);
      var resp = await getMethod(data);
      setSiteLoader(false);
      if (resp.status == true) {
        setprofileData(resp.datas.userDetails);
      }
    } catch (error) { }
  };

  const claimAirdrop = async() => {
    try {
      var data = {
        apiUrl: apiService.claimairdropreward,
      };
      setbuttonLoader(true);
      var resp = await postMethod(data);
      setbuttonLoader(false);
      if (resp.status) {
        // console.log(resp,"----resp----");
        showsuccessToast(resp.message);
        getProfile();
      } else {
        showerrorToast(resp.message);
      }
    } catch (error) {
    }
  }

  const showsuccessToast = (message) => {
    toast.dismiss();
    toast.success(message);
  };

  const showerrorToast = (message) => {
    toast.dismiss();
    toast.error(message);
  };

  return (
    <>
      <Header />
      {siteLoader == true ? (
        <div className="loadercss">
          <Bars
            height="80"
            width="80"
            color="#ffc630"
            ariaLabel="bars-loading"
            wrapperStyle={{}}
            wrapperClass=""
            visible={true}
          />
        </div>
      ) : (
        <div className="">
          <div className="referral-hero referral-hero1">
            <div className="container">
              <div className="row refeferal_section">
                <div className="col-lg-7">
                  <div className="refeferal_section_content referral-hero2">
                    <h2 className="stake-title">
                    Unlock Rewards and <span className="prim">Boost</span>  <br/>
                    Your Crypto
                    </h2>
                    <h6 className="stake-msg">
                      Earn exclusive benefits as you trade and grow your crypto portfolio.
                    </h6>
                  </div>
                </div>

                <div className="col-lg-5 mar-top-3">
                  <img src={require("../assets/Rewards.png")} width="100%" />
                </div>
              </div>
            </div>
          </div>

          <section className="my-refferal-eraning-section">
            <div className="container">
            <div className="my-refferal-eraning">
            <h2 className="my-refferal-eraning-title">Claim Your Airdrop Rewards</h2>
            <div className="row">
            <div className="col-lg-12 my-4">
            <div className="rewards_card">
                <div>
                <h5>Claim Your Airdrop Rewards</h5>
                <p>Your rewards are ready! Click to claim your airdrop and boost your Voltrix crypt holdings.</p>
                </div>
                <div>
                  {buttonLoader == false ? (
                    <>
                  {profileData.kycstatus == 1 ? (
                    <>
                    {Rewardpageref.current.userAirdropStatus == 0 ? (
                      <button className="airstatus_dis">No rewards</button>
                    ) : (
                      <button onClick={claimAirdrop} >Claim Now</button>
                    )}
                    </>
                  ) : (
                    <button onClick={() => navigate("/kyc")}>Complete KYC</button>
                  )}
                    </>
                  ) : (
<button>Loading...</button>
                  )}

                
                </div>
              </div>
              </div>
            </div>
              </div>
              
              <div className="my-refferal-eraning">
                <h2 className="my-refferal-eraning-title">New User Tasks</h2>
                <div className="row">
                  {Rewardpageref.current?.activeRewards?.depositRewardActive ||
                    Rewardpageref.current?.activeRewards?.kycRewardActive ||
                    Rewardpageref.current?.activeRewards?.tradeRewardActive ? (
                    <>
                      {/* KYC Reward */}
                      {Rewardpageref.current?.activeRewards?.kycRewardActive && (
                        <div className="col-lg-12 my-4">
                          <div className="rewards_card">
                            <div>
                              <h5>Verify Your Identity and Get Rewarded</h5>
                              <p>Complete your KYC verification to receive up to {Rewardpageref.current?.kycAmount} {Rewardpageref.current?.kycCurrency}. Ensure your account's credibility and unlock exclusive benefits.</p>
                            </div>
                            <div>{
                              Rewardpageref.current?.userKYCStatus == 1 ? (
                                <button onClick={claimKYC} >Claim Now</button>
                              ) : Rewardpageref.current?.userKYCStatus == 2 ? (
                                <button >Claimed</button>
                              ) : (
                                <button onClick={() => navigate("/kyc")}>Complete KYC</button>
                              )}

                            </div>
                          </div>
                        </div>
                      )}

                      {/* Deposit Reward */}
                      {Rewardpageref.current?.activeRewards?.depositRewardActive && (
                        <div className="col-lg-12 my-4">
                          <div className="rewards_card">
                            <div>
                              <h5>Boost Your Balance</h5>
                              <p>Deposit at least {Rewardpageref.current?.minDeposit} USDT and claim your bonus up to {Rewardpageref.current?.depositAmount} {Rewardpageref.current?.depositCurrency}. It's the perfect time to grow your portfolio with extra rewards!</p>
                            </div>
                            <div>
                              {
                                Rewardpageref.current?.userDepositStatus == 1 ? (
                                  <button onClick={claimDEPOSIT} >Claim Now</button>
                                ) : Rewardpageref.current?.userDepositStatus == 2 ? (
                                  <button >Claimed</button>
                                ) : (
                                  <button onClick={() => navigate("/deposit")}>Deposit Now</button>
                                )
                              }

                            </div>
                          </div>
                        </div>
                      )}

                      {/* Trade Reward */}
                      {Rewardpageref.current?.activeRewards?.tradeRewardActive && (
                        <div className="col-lg-12 my-4">
                          <div className="rewards_card">
                            <div>
                              <h5>Make Your First Trade and Win Big</h5>
                              <p>Complete your first trade to receive up to {Rewardpageref.current?.tradeAmount} {Rewardpageref.current?.tradeCurrency}. Start trading and enjoy the benefits of seamless transactions!</p>
                            </div>
                            <div>
                              {
                                Rewardpageref.current?.userTradeStatus == 1 ? (
                                  <button onClick={claimTRADE} >Claim Now</button>
                                ) : Rewardpageref.current?.userTradeStatus == 2 ? (
                                  <button >Claimed</button>
                                ) : (
                                  <button onClick={() => navigate("/trade/BTC_USDT")}>Trade Now</button>
                                )
                              }

                            </div>
                          </div>
                        </div>
                      )}
                    </>
                  ) : (
                    <div className="empty_data">
                      <div className="empty_data_img">
                        <img
                          src={require("../assets/No-data.webp")}
                          width="100px"
                          className="d-block mx-auto"
                          alt="No data"
                        />
                      </div>
                      <div className="no_records_text text-center">
                        No Tasks Found
                      </div>
                    </div>
                  )}
                </div>
              </div>
            </div>
          </section>


          {/* staking options */}
          {/* <div>
            <div className="container">
              <div className="staking-flex">
                <h5 className="opt-title">Reward History</h5>
                <Link to="/referralHistory">
                  <div className="ref_view_main">
                    <span className="invite_text">View All</span>
                    <i
                      class="ri-arrow-right-s-line"
                      style={{ color: "#ffc630" }}
                    ></i>
                  </div>
                </Link>
              </div>

              <div className="table-responsive table-cont dash_table_content">
                <table className="table ">
                  <thead>
                    <tr className="stake-head font-satoshi">

                      <th className="table_center_text">S.No</th>
                      <th className="table_center_text">Username</th>
                      <th className="table_center_text">Date & Time</th>
                    </tr>
                  </thead>

                  <tbody>

                    {refferHistoty.length > 0 ? (
                      refferHistoty.slice(0, 5).map((item, i) => {
                        return (
                          <tr key={i}>
                            <td className="opt-term font-satoshi font_14 table_center_text">
                              {i + 1}
                            </td>
                            <td className="opt-term font-satoshi font_14 table_center_text">
                              {item.displayname}
                            </td>
                            <td className="opt-term font-satoshi font_14 table_center_text">
                              {moment(item.createdDate).format("lll")}
                            </td>
                          </tr>
                        )
                      })
                    ) : (
                      <tr>
                        <td colSpan={3} className="text-center py-5">
                          <div className="empty_data">
                            <div className="empty_data_img">
                              <img
                                src={require("../assets/No-data.webp")}
                                width="100px"
                              />
                            </div>
                            <div className="no_records_text">
                              No Records Found
                            </div>
                          </div>
                        </td>
                      </tr>
                    )}
                  </tbody>
                </table>
              </div>
            </div>
          </div> */}
        </div>
      )}
    </>
  );
};

export default Staking;
